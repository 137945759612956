$(window).on("load", function () {

	function OpenWindow(targetWindow) {
		$(targetWindow).arcticmodal({
			closeOnEsc: false,
			closeOnOverlayClick: false,
			openEffect: {
				type: "fade",
				speed: 300
			},
			overlay: {
				css: {
					backgroundColor: "#000000",
					opacity: .75
				}
			}
		});
	}

	$(".open-modal").click(function (e) {
		e.preventDefault();
		OpenWindow(".js-order_window");
	});

});

function showHidePassword(target) {
	let input = document.getElementById('password-input');
	if (input.getAttribute('type') == 'password') {
		target.classList.add('view');
		input.setAttribute('type', 'text');
	} else {
		target.classList.remove('view');
		input.setAttribute('type', 'password');
	}
	return false;
}

window.showHidePassword = showHidePassword;